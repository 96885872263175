<template>
  <div>
    <div class="row mt-3 mb-3">
      <h1 class="col-sm-12 text-right">Tickets</h1>
    </div>

    <div class="d-flex flex-wrap">
      
      <div class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img src="https://static.bienparabien.com/constelacion/img/tickets_v2.png" alt="Tickets" width="80px" />
            </div>
            <h4 class="card-title">Tickets</h4>
            <p class="card-subtitle text-muted">Administracion de tickets</p>
            <router-link :to="{name: 'tickets_listado'}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>
    </div>

    <template v-if="$auth.can('tickets','usuario_administrador')">
      <div class="row">
        <h2 class="col-sm-12 text-right">Administrador</h2>
      </div>

      <div class="d-flex flex-wrap">
        
        <div class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <div class="text-center">
                <img src="https://static.bienparabien.com/constelacion/img/users.png" alt="Catálogos" width="80px" />
              </div>
              <h4 class="card-title">Usuarios</h4>
              <p class="card-subtitle text-muted">Administracion de usuarios</p>
              <router-link :to="{name: 'tickets_usuarios'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
        
        <div class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <div class="text-center">
                <img src="https://static.bienparabien.com/constelacion/img/steps.png" alt="Etapas" width="80px" />
              </div>
              <h4 class="card-title">Etapas</h4>
              <p class="card-subtitle text-muted">Administracion de etapas</p>
              <router-link :to="{name: 'tickets_etapas'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
        
        <div class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <div class="text-center">
                <img src="https://static.bienparabien.com/constelacion/img/folders.png" alt="Etapas" width="80px" />
              </div>
              <h4 class="card-title">Requerimientos</h4>
              <p class="card-subtitle text-muted">Administracion de requerimientos</p>
              <router-link :to="{name: 'tickets_requerimientos'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$auth.can('tickets','usuario_super_administrador')">
      <div class="row">
        <h2 class="col-sm-12 text-right">Super administrador</h2>
      </div>

      <div class="d-flex flex-wrap">
        <div class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <div class="text-center">
                <img src="https://static.bienparabien.com/constelacion/img/users.png" alt="Áreas" width="80px" />
              </div>
              <h4 class="card-title">Áreas</h4>
              <p class="card-subtitle text-muted">Administracion de áreas</p>
              <router-link :to="{name: 'tickets_areas'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script type="text/javascript">
  export default {
    mounted() {
      let partes = [];

      let menu = {
        title: 'Tickets'
        ,tickets_listado: 'Listado'
      }
      partes.push(menu);

      if (this.$auth.can('tickets','usuario_administrador')) {
        let admin = {
          title: 'Administrador',
          tickets_usuarios: 'Usuarios',
          tickets_etapas: 'Etapas',
          tickets_requerimientos: 'Requerimientos'
        }
        partes.push(admin);
      }

      if (this.$auth.can('tickets','usuario_super_administrador')) {
        let superadmin = {
          title: 'Super administrador',
          tickets_areas: 'Áreas'
        }
        partes.push(superadmin);
      }

      this.$store.commit('setMenu',partes);
    }
  }
</script>